import { Link } from 'gatsby';
import React from 'react';
import CourseUnitCard from '../../components/CourseUnitCard';
import { Column, Container, Row } from '../../components/Grid';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import Action from '../../components/Resource/Action';
import Section from '../../components/Section';
import Subfooter from '../../components/Subfooter';
import '../../styles/styles.scss';

const IndexPage = () => (
  <Layout title="Educators in Action">
    <Hero
      classNames={'hero--small course__hero'}
      modal
      imageSource="header-educators-in-action@2x.jpg"
      guidSrc="999674c7-16b8-4f14-a5ef-fd6149dc7d58"
      posterSrc={'inspiration-video-stills.gif'}
      color={'blue'}
      playtext={'FUTURELAB+ IN ACTION'}
    >
      <h1>Educators in Action</h1>
      <h3>
        Inspiring the Next Generation of Diverse Science and Health
        Professionals
      </h3>
      <p>
        Join Futurelab+ as we build a community of practice for science
        educators and their students in communities nationwide. Dive into
        real-life teaching situations to see how other educators are
        facilitating important and empowering biotechnology lessons in class
        using a culturally competent lens.
      </p>
    </Hero>
    {/* <Section>
      <Container>
        <Row>
          <Column size={6}>
            <Image filename="img-college-career-readiness-02-many-faces.png" />
          </Column>
          <Column size={5}>
            <h3 className="home__bold-heading">
              Futurelab+ Professional Learning Community
            </h3>
            <p>
              Apply to join the Futurelab+ PLC and bring biotech into your
              classroom! Look out for information about our 2024 program in
              January 2024. It will feature a week-long Summer Institute that
              includes hands-on lab workshops with BABEC, and will be followed
              by opportunities throughout the 2024/25 school year.
            </p>
            <Link
              className="home__guiding-principles-learning-more"
              to="https://www.igniteducation.org/futurelabplus/"
            >
              APPLY NOW
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
    <Callout
      className="home__callout mb-3"
      to="https://futurelabplus-network.gene.com/v2"
    >
      Join our community of teachers and biotech experts through FutureLab+
      Network
    </Callout> */}
    <Section className="educators-action__at-a-glance-section pt-0">
      <Container fullWidth className="educators-action__at-a-glance-container">
        <Row>
          <Column size={12}>
            <h5 className="mb-0">VIDEO SERIES</h5>
            <h3 className="educators-action__at-a-glance-header">Objectives</h3>
          </Column>
        </Row>
        <Row>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-engage.svg" />
            <h4 className="educators-action__at-a-glance-section-header mt-0">
              ENGAGE
            </h4>
            <p>
              Engage students from diverse racial, ethnic, and cultural groups
              with quality, equitable, liberating educational experiences.
            </p>
          </Column>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-illustrate.svg" />
            <h4 className="educators-action__at-a-glance-section-header mt-0">
              ILLUSTRATE
            </h4>
            <p>
              Illustrate how educators use Futurelab+ resources to empower their
              students to become change agents in their community.
            </p>
          </Column>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-equip.svg" />
            <h4 className="educators-action__at-a-glance-section-header mt-0">
              EQUIP
            </h4>
            <p>
              Equip educators with knowledge and tools to support necessary
              conversations in the high school science classroom and beyond.
            </p>
          </Column>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-connect.svg" />
            <h4 className="educators-action__at-a-glance-section-header mt-0">
              CONNECT
            </h4>
            <p>
              Connect educators to the Product Life Cycle and the wide breadth
              of career pathways across biotechnology.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-0">
      <Container fullWidth>
        <Row className="pb-1">
          <Column size={8}>
            <h4 className="educators-action__above-header">Video Series</h4>
            <h3 className="educators-action__getting-started-header mt-0 mb-0.5">
              Getting Started
            </h3>
            <p className="educators-action__getting-started-desc">
              The Futurelab+ Master Class series provides a multi-faceted look
              at biotech in the classroom through the eyes of three unique
              educators. Discover how you can apply biotech principles in
              diverse learning environments using two essential themes, which
              provide a simple framework to develop your instructional
              practices.
            </p>
          </Column>
        </Row>
        <CourseUnitCard
          unitIdentifier="Theme One"
          header="Empowering Students to Solve Real-World Problems"
          subheader="3 videos | Educator Guide"
          description="Provide students with the tools to seek meaningful change in their world
          through the exciting field of biotechnology. Discover how biotech classrooms are addressing
          community problems, health literacy, social justice issues and more."
          img="thmb-educators-theme-one@2x.jpg"
          to="/educators-in-action/theme-one/sam-long"
        />
        <CourseUnitCard
          unitIdentifier="Theme Two"
          header="Building Pedagogical Practices for Inclusive Classrooms"
          subheader="3 videos | Educator Guide"
          description="Learn timely ways to implement pedagogical practices that support safe and
          inclusive classroom environments. Find out how other educators are inspiring the workforce
          of tomorrow through culturally and linguistically relevant biotech experiences."
          img="thmb-educators-theme-two@2x.jpg"
          to="/educators-in-action/theme-two/sam-long"
        />
      </Container>
    </Section>
    <Section className="capstone-project__masterclass-call-to-action-section mt-3 mb-3">
      <Container>
        <Row className="mt-0 mb-0 content">
          <Column size={7}>
            <Image filename="img-capstone-masterclass-cta@2x.png" />
          </Column>
          <Column size={4} className="pt-1 pb-1">
            <div className="text-wrapper">
              <div className="text pr-1">
                <h3 className="text-bold mt-0 mb-0">
                  CAPSTONE MASTERCLASS SERIES
                </h3>
                <p className="pt-0.5">
                  Meet Capstone educators to learn more about the next
                  generation of diverse science and health professionals.
                </p>
              </div>
              <Link
                to={
                  '/classroom-instruction/capstone-masterclass-series/project-overview/'
                }
              >
                <button className="call-to-action__button">
                  <span className="call-to-action__icon">
                    <Icon name="arrowright" />
                  </span>
                </button>
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-0">
      <Container fullWidth>
        <Row className="pb-1">
          <Column size={8}>
            <h4 className="educators-action__above-header">
              Educators in Action
            </h4>
            <h3 className="educators-action__getting-started-header mt-0 mb-0.5">
              Featured Educators
            </h3>
            <p className="educators-action__getting-started-desc">
              Meet three teachers from across the United States who are bringing
              biotech to life in student's everyday world.
            </p>
          </Column>
        </Row>
        <Row className="pb-1 mb-3">
          <Column size={3}>
            <div className="educators-action__profile-thumbs">
              <Image filename="thmb-educators-profile-long@2x.png" />
            </div>
          </Column>
          <Column size={7}>
            <h3 className="educators-action__featured-educators-name mb-0">
              Sam Long <span>(he/him)</span>
            </h3>
            <h4 className="educators-action__featured-educators-subject mt-0 mb-0.5">
              High School Science
            </h4>
            <h4 className="educators-action__featured-educators-school mt-0 mb-0.5">
              St. Vrain Valley Schools, Denver, Colorado
            </h4>
            <p className="educators-action__featured-educators-desc">
              Sam is passionate about engaging every student's perspective in
              the biotechnology conversation, while being sure to ground every
              discussion in real-world evidence.
            </p>
            <h4 className="educators-action__featured-educators-videos mt-0 mb-0.5">
              Featured Videos:
            </h4>
            <ul className="educators-action__featured-educators-links">
              <li>
                <Link to="/educators-in-action/theme-one/sam-long/">
                  Empowering Students to Solve Inequities in the Biotech
                  Classroom
                </Link>
              </li>
              <li>
                <Link to="/educators-in-action/theme-two/sam-long/">
                  How to Have Necessary Conversations in the Biotech Classroom
                </Link>
              </li>
            </ul>
          </Column>
        </Row>
        <Row className="pb-1 mb-3">
          <Column size={3}>
            <div className="educators-action__profile-thumbs">
              <Image filename="thmb-educators-profile-delgado@2x.png" />
            </div>
          </Column>
          <Column size={7}>
            <h3 className="educators-action__featured-educators-name mb-0">
              Pedro Delgado <span>(he/him)</span>
            </h3>
            <h4 className="educators-action__featured-educators-subject mt-0 mb-0.5">
              Computer Science and Science
            </h4>
            <h4 className="educators-action__featured-educators-school mt-0 mb-0.5">
              Young Women's STEAM and Preparatory Academy, El Paso, Texas
            </h4>
            <p className="educators-action__featured-educators-desc">
              Pete is a passionate advocate for using computational thinking and
              project based learning framework to engage students from diverse
              backgrounds in real-world learning experiences.
            </p>
            <h4 className="educators-action__featured-educators-videos mt-0 mb-0.5">
              Featured Videos:
            </h4>
            <ul className="educators-action__featured-educators-links">
              <li>
                <Link to="/educators-in-action/theme-one/pedro-delgado/">
                  Incorporating Project Based Learning into the Biotech
                  Classroom
                </Link>
              </li>
              <li>
                <Link to="/educators-in-action/theme-two/pedro-delgado/">
                  Computational Thinking in Action in the Biotech Classroom
                </Link>
              </li>
            </ul>
          </Column>
        </Row>
        <Row className="pb-1 mb-5">
          <Column size={3}>
            <div className="educators-action__profile-thumbs">
              <Image filename="thmb-educators-profile-tapia@2x.png" />
            </div>
          </Column>
          <Column size={7}>
            <h3 className="educators-action__featured-educators-name mb-0">
              Guadalupe Tapia <span>(she/her)</span>
            </h3>
            <h4 className="educators-action__featured-educators-subject mt-0 mb-0.5">
              Biotechnology
            </h4>
            <h4 className="educators-action__featured-educators-school mt-0 mb-0.5">
              Maxine Silva Health Magnet School, El Paso, Texas
            </h4>
            <p className="educators-action__featured-educators-desc">
              Guadalupe uses a student-centered approach to connect diverse
              learners to the limitless opportunities available throughout the
              Product Life Cycle.
            </p>
            <h4 className="educators-action__featured-educators-videos mt-0 mb-0.5">
              Featured Videos:
            </h4>
            <ul className="educators-action__featured-educators-links">
              <li>
                <Link to="/educators-in-action/theme-one/guadalupe-tapia/">
                  Connections to the Product Life Cycle
                </Link>
              </li>
              <li>
                <Link to="/educators-in-action/theme-two/guadalupe-tapia/">
                  Equity Practices in the Biotech Classroom
                </Link>
              </li>
            </ul>
          </Column>
        </Row>
        <Row className="pb-1 mr-2 mb-3">
          <Column size={8}>
            <h4 className="educators-action__above-header">
              Educators in Action
            </h4>
            <h3 className="educators-action__getting-started-header mt-0 mb-0.5">
              About the Program
            </h3>
            <p className="educators-action__getting-started-desc">
              Futurelab+ resources are designed to create a bridge between
              student's daily lives and the evolving STEM workforce of the
              future. Students can practice in-demand skills that are needed
              across the full biotech product lifecycle, from molecule to
              medicine. Aligned to both national education and industry
              standards, all units are problem-based and built around workforce
              skill development, to empower students with the knowledge and
              tools needed to tackle health disparities—locally and globally.
            </p>
          </Column>
          <Column size={4}>
            <div className="educators-action__educator-guide">
              <div className="educators-action__educator-guide-heading">
                <h4>Educator Guide</h4>
              </div>
              <div className="educators-action__educator-guide-body">
                <h4>Educators in Action</h4>
                <h3 className="educators-action__educator-guide-content-heading">
                  Educator Guide
                </h3>
                <span className="educators-action__educator-guide-duration">
                  10-15 minutes
                </span>
                <p>
                  This in-depth companion to the Master Class series provides
                  educators with practical real-world connections to explore
                  biotechnology in any classroom environment. Build lessons
                  using key background information, educator strategies and
                  more.
                </p>
                <Action
                  type="download"
                  label="Educator Guide"
                  path="/pdfs/educators-in-action/Genentech-Masterclass-Ed-Guide.pdf"
                  format=".PDF,"
                  size="722 KB"
                />
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-0">
      <Subfooter content="Build timely curriculum connections for a new generation of problem-solvers with transdisciplinary biotech resources." />
    </Section>
  </Layout>
);

export default IndexPage;
